<template>
  <v-container
    fluid
  >
    <v-card>
      <v-card-actions
      >
        {{ dateRangeText }}
        <v-spacer></v-spacer>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle
              v-model="rangeSelected"
              color="primary"
            >
              <v-btn
                v-bind="attrs"
                v-on="on"
                :value="'range'"
                class="text-white"
                text
              >
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
          <v-date-picker
            color="primary"
            v-model="datesPicked"
            no-title
            scrollable
            range
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> {{ $vuetify.lang.t('$vuetify.common.cancel') }}</v-btn>
            <v-btn text color="primary" @click="handleDateRangeChange('range')">{{ $vuetify.lang.t('$vuetify.common.ok') }}</v-btn>
          </v-date-picker>
        </v-menu>


        <div class="mx-4"></div>

        <v-btn-toggle
          v-model="rangeSelected"
          color="primary"
        >
          <v-btn
            :value="'week'"
            @click="handleDateRangeChange('week')"
          >
            {{ $vuetify.lang.t('$vuetify.common.week') }}
          </v-btn>

          <v-btn
            :value="'month'"
            @click="handleDateRangeChange('month')"
          >
            {{ $vuetify.lang.t('$vuetify.common.month') }}
          </v-btn>
        </v-btn-toggle>
      </v-card-actions>
      <v-card-text
      >
        <v-row>
          <v-col
            cols="6"
          >
            <blood-pressure
              :patient-id="patientId"
              :date-range="dateRange"
            />
          </v-col>

          <v-col
            cols="6"
          >
            <blood-oxygen
              :patient-id="patientId"
              :date-range="dateRange"
            />
          </v-col>

          <v-col
            cols="6"
          >
            <heart-rate
              :patient-id="patientId"
              :date-range="dateRange"
            />
          </v-col>

          <v-col
            cols="6"
          >
            <sport
              :patient-id="patientId"
              :date-range="dateRange"
            />
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {

    components: {
      BloodPressure: () => import('./datas/BloodPressure'),
      BloodOxygen: () => import('./datas/BloodOxygen'),
      HeartRate: () => import('./datas/HeartRate'),
      Sport: () => import('./datas/Sport')
    },

    props: {
      patientId: {
        type: String,
        default: () => null
      }
    },

    data () {
      return {
        menu: false,
        rangeSelected: 'week',
        datesPicked: [],
        dateRange: [1, 2]
      }
    },

    computed: {

      ...mapGetters({
        currentHealthReport: 'healthReport/currentHealthReport'
      }),

      dateRangeText () {
        return this.dateRange.join(' ~ ')
      }

    },

    created () {
      this.handleDateRangeChange()
    },

    methods: {

      ...mapActions({
        healthReportShow: 'healthReport/show'
      }),

      handleGoBack () {
        this.$router.go(-1)
      },

      handleDateRangeChange ( rangeType = 'week') {
        if (rangeType === 'range') {
          if (this.datesPicked.length === 2) {
            this.dateRange = this.datesPicked
            this.dateRange.sort()
            this.menu = false
            return
          }
        }

        let dates = []

        if (rangeType === 'week') {
          dates.push(this.$moment().subtract(7, 'days').format('YYYY-MM-DD'))
        }

        if (rangeType === 'month') {
          dates.push(this.$moment().subtract(1, 'months').format('YYYY-MM-DD'))
        }

        dates.push(this.$moment().format('YYYY-MM-DD'))

        if (dates.length === 2) {
          this.dateRange = dates
        }
      }

    }
  }
</script>
